import type { RawWidgetConfigFragment } from '../gql/fragments/__generated/RawWidgetConfig';
import type { WhlModuleType } from '../models/WhlModuleType';

export default (
  widgetConfig: MaybeRef<RawWidgetConfigFragment | null>,
  module: WhlModuleType
): boolean => {
  const enabledTypes =
    (toValue(widgetConfig)?.usageForEntityType ?? '').split(',') ?? [];

  if (enabledTypes.includes(module)) {
    return true;
  } else {
    throw createError({
      statusCode: 404,
      statusMessage: 'Page Not Found (Module disabled)',
      fatal: true,
    });
  }
};
